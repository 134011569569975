<template>
  <div class="md-layout-item md-size-100">
    <md-button
      class="md-just-icon md-round md-primary pull-right"
      @click="showModal = true"
    >
      <md-icon>add</md-icon>
      <md-tooltip>Create Calendar Day</md-tooltip>
    </md-button>

    <vue-table
      v-if="vTable.headers"
      ref="vtable"
      :values="vTable.values"
      :headers="vTable.headers"
      :actions="vTable.actions"
      :options="{
        tableClass:'table'
      }"
      @changed="getData"
      @onEdit="onEdit"
      @onDelete="onDelete"
    />
    <CalendarModal
      v-if="showModal"
      :item="selectedItem"
      @close="closeModal"
      @saved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import CalendarModal from './CalendarModal.vue';

export default {
  components: {
    VueTable,
    CalendarModal,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'title',
            sortable: true,
          },
          {
            title: 'category.title',
            mask: 'category',
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Calendar Day Details',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Calendar Day',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      showModal: false,
      selectedItem: null,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getData(params) {
      this.request('app/calendar', 'get', { ...params }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onDelete(item) {
      this.request(`app/calendar/${item.calendar_day_id}`, 'delete', null, () => {
        this.fireSuccess('Calendar Day deleted successfully');
        this.$refs.vtable.init();
      });
    },
    closeModal() {
      this.showModal = false;
      this.selectedItem = null;
    },
    onEdit(item) {
      this.showModal = true;
      this.selectedItem = item;
    },
  },
};
</script>

<style scoped>
</style>
