<template>
  <div class="md-layout-item md-size-100">
    <md-button
      class="md-just-icon md-round md-primary pull-right"
      @click="createNewCategory"
    >
      <md-icon>add</md-icon>
      <md-tooltip>Create Calendar Category</md-tooltip>
    </md-button>

    <vue-table
      v-if="vTable.headers"
      ref="vtable"
      :values="vTable.values"
      :headers="vTable.headers"
      :actions="vTable.actions"
      :options="{
        tableClass:'table'
      }"
      @changed="getData"
      @onEdit="onEdit"
      @onDelete="onDelete"
    />

    <CalendarCategoryModal
      v-if="showModal"
      :item="selectedItem"
      @close="onCloseModal"
      @saved="init"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import CalendarCategoryModal from './CalendarCategoryModal.vue';

export default {
  components: {
    VueTable,
    CalendarCategoryModal,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'title',
            sortable: true,
          },
          {
            title: 'hex_color',
            mask: 'color',
            color: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Calendar Day Details',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Calendar Day',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      selectedItem: null,
      showModal: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$refs.vtable.init();
    },
    getData(params) {
      this.request('app/calendar/categories', 'get', { ...params }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    createNewCategory() {
      this.showModal = true;
    },
    onEdit(item) {
      this.showModal = true;
      this.selectedItem = item;
    },
    onCloseModal() {
      this.showModal = false;
      this.selectedItem = null;
    },
    onDelete(item) {
      this.request(`app/calendar/categories/${item.calendar_category_id}`, 'delete', null, () => {
        this.fireSuccess('Calendar Category deleted successfully');
        this.$refs.vtable.init();
      });
    },
  },
};
</script>
