<template>
  <md-card class="md-card-global-sales">
    <md-card-header class="md-card-header-icon md-card-header-blue">
      <div class="card-icon">
        <md-icon>assignment</md-icon>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <tabs
          plain
          :tab-name="[
            'Calendar Days',
            'Calendar Categories',
          ]"
          color-button="success"
        >
          <template slot="tab-pane-1">
            <CalendarDays />
          </template>
          <template slot="tab-pane-2">
            <CalendarCategories />
          </template>
        </tabs>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { Tabs } from '@/components';
import CalendarDays from './CalendarDays.vue';
import CalendarCategories from './CalendarCategories.vue';

export default {
  components: {
    Tabs,
    CalendarDays,
    CalendarCategories,
  },
};
</script>

<style scoped>
</style>
