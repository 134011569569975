<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      CALENDAR CATEGORY FORM
    </template>
    <template slot="body">
      <FormText
        v-model="form.title"
        icon="description"
        label="Title"
      />
      <FormColor
        v-model="form.hex_color"
        label="Color"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-success md-sm"
        @click="saveForm"
      >
        SAVE
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormText, FormColor } from '@/components/Inputs';

export default {
  components: {
    FormColor,
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      title: null,
      hex_color: '#26c6da',
    },
  }),
  mounted() {
    if (this.item) {
      this.form = { ...this.item };
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveForm() {
      if (this.form.calendar_category_id) {
        this.request(`app/calendar/categories/${this.form.calendar_category_id}`, 'put', this.form, () => {
          this.fireSuccess('Calendar Category Updated Successfully');
          this.$emit('saved');
          this.close();
        });
      } else {
        this.request('app/calendar/categories', 'post', this.form, () => {
          this.fireSuccess('Calendar Category Created Successfully');
          this.$emit('saved');
          this.close();
        });
      }
    },
  },
};
</script>
