<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      CALENDAR FORM
    </template>
    <template slot="body">
      <FormDate
        v-model="form.date"
        label="Date"
      />
      <FormText
        v-model="form.title"
        icon="description"
        label="Title"
      />
      <CalendarCategoriesSelect
        v-model="form.category_id"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-success md-sm"
        @click="saveForm"
      >
        SAVE
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormDate, FormText } from '@/components/Inputs';
import { CalendarCategoriesSelect } from '@/components/Inputs/selects';

export default {
  components: {
    FormDate,
    FormText,
    CalendarCategoriesSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      date: null,
      title: null,
      category_id: null,
    },
  }),
  mounted() {
    if (this.item) {
      this.form = { ...this.item };
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveForm() {
      if (this.form.calendar_day_id) {
        this.request(`app/calendar/${this.form.calendar_day_id}`, 'put', this.form, () => {
          this.fireSuccess('Calendar Day Updated Successfully');
          this.$emit('saved');
          this.close();
        });
      } else {
        this.request('app/calendar', 'post', this.form, () => {
          this.fireSuccess('Calendar Day Created Successfully');
          this.$emit('saved');
          this.close();
        });
      }
    },
  },
};
</script>
